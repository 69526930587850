import { css } from '@emotion/react'
import React from 'react'
import { HeadingTertiary } from '../../components/atoms/Heading/HeadingTertiary'
import { LinkAnchor } from '../../components/atoms/Link/LinkAnchor'
import { ENGLISH_TITLE } from '../../components/data/English'
import { faqList } from '../../components/data/faqList'
import { SEOIR } from '../../components/data/SEO'
import { AccordionFAQ } from '../../components/molecules/Accordion/AccordionFAQ'
import { LayoutIR } from '../../components/organisms/Layout/LayoutIR'
import { colorList } from '../../components/styles/color'

const faq1 = {
  id: 'heading1',
  label: '決算・財務情報',
  faq: faqList.slice(0, 2),
}

const faq2 = {
  id: 'heading2',
  label: 'CRGホールディングスについて',
  faq: faqList.slice(2, 5),
}

const faq3 = {
  id: 'heading3',
  label: '株式全般',
  faq: faqList.slice(5),
}

/**
 * ページコンポーネント
 */
/** */
const Page = () => {
  return (
    <LayoutIR
      heading="よくあるご質問"
      headingEnglish={ENGLISH_TITLE.FAQ}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: 'よくあるご質問', url: '' },
      ]}
    >
      <div>
        <div className="pc:py-6 pc:px-8 bg-white">
          <ul className="grid grid-cols-2 gap-x-2 gap-y-4 pc:gap-0 pc:flex pc:flex-wrap pc:space-x-4 ">
            {[
              { text: '決算・財務情報', to: 'heading1' },
              { text: 'CRGホールディングスについて', to: 'heading2' },
              { text: '株式全般', to: 'heading3' },
            ].map((item, i) => (
              <LinkAnchor key={i} {...item} />
            ))}
          </ul>
        </div>
        <div>
          {[faq1, faq2, faq3].map((list, i) => (
            <section key={i} className="mt-8 py-8 pc:px-8 bg-white">
              <div id={list.id}>
                <HeadingTertiary label={list.label} />
              </div>
              <dl
                className="mt-8"
                css={css`
                  & > dt:last-of-type {
                    border-bottom: 1px solid ${colorList.border10};
                  }
                `}
              >
                {list.faq.map((item, i) => (
                  <AccordionFAQ
                    key={i}
                    question={item.question}
                    answer={item.answer}
                  />
                ))}
              </dl>
            </section>
          ))}
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEOIR title="よくあるご質問" url="/ir/faq" />
